import React, { useState } from "react";
import NewsletterForm from "./NewsletterForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = process.env.REACT_APP_MAILCHIMP_URL;

const Newsletter = () => {
  const [isMessageActive, setIsMessageActive] = useState(false);
  return (
    <>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <div>
            <NewsletterForm
              onSubmitted={(formData) => subscribe(formData)}
              setIsMessageActive={setIsMessageActive}
            />
            {isMessageActive && status === "sending" && (
              <div className="newsletter-message newsletter-message--sending">
                <span className="newsletter-message--loader">
                  ....<span className="newsletter-message--loader-hide"></span>
                </span>
              </div>
            )}
            {isMessageActive && status === "error" && (
              <div
                className="newsletter-message newsletter-message--error"
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
            )}
            {isMessageActive && status === "success" && (
              <div className="newsletter-message newsletter-message--success">
                Subscribed !
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

export default Newsletter;
