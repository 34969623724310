import React, { useState } from "react";

const NewsletterForm = (props) => {
  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [mailError, setMailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  function resetForm() {
    setName("");
    setMail("");
  }

  function submit(e) {
    e.preventDefault();
    props.setIsMessageActive(false);
    setMailError(false);
    setNameError(false);
    let isValidate = true;
    let validate = { name, mail };
    let regExpName = /^[a-zA-Z].*[\s.]*$/;
    let regExpEmail = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (var key of Object.keys(validate)) {
      if (!validate[key].length > 0) {
        isValidate = false;
        switch (key) {
          case "mail":
            setMailError(true);
            break;
          case "name":
            setNameError(true);
            break;
          default:
            break;
        }
      }
    }
    if (!regExpName.test(String(name).toLowerCase())) {
      isValidate = false;
      setNameError(true);
    }
    if (!regExpEmail.test(String(mail).toLowerCase())) {
      isValidate = false;
      setMailError(true);
    }
    if (isValidate) {
      props.onSubmitted({
        EMAIL: mail,
        FNAME: name,
      });
      props.setIsMessageActive(true);
      resetForm();
    }
  }
  return (
    <form
      className="d-flex flex-column flex-md-row newsletter"
      onSubmit={submit}
    >
      <h3 className="n_form_item n_form_title">Nasz newsletter</h3>
      <input
        className="n_form_input n_form_item"
        type="text"
        placeholder="TWOJE IMIĘ"
        value={name}
        onChange={(e) => setName(e.target.value)}
        iserror={nameError.toString()}
        formNoValidate
      />
      <input
        className="n_form_input n_form_item"
        type="email"
        placeholder="TWÓJ ADRES EMAIL"
        value={mail}
        onChange={(e) => setMail(e.target.value)}
        iserror={mailError.toString()}
        formNoValidate
      />
      <input
        className="n_form_btn n_form_item"
        type="submit"
        value="ZAPISZ SIĘ TERAZ"
      />
    </form>
  );
};

export default NewsletterForm;
