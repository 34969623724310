import React, { Component } from "react";
import $ from "jquery";

const initialState = {
  name: "",
  email: "",
  title: "",
  message: "",
  mailSent: false,
  error: null,
  msg: 0,
  nameHelp: false,
  emailHelp: false,
  titleHelp: false,
  messageHelp: false,
};

class ContactForm extends Component {
  state = {
    ...initialState,
  };

  handleFormSubmit(event) {
    event.preventDefault();
    if (this.validateForm(this.state)) {
      let form = {
        Name: this.state.name,
        Email: this.state.email,
        Title: this.state.title,
        Messages: this.state.message,
      };
      $.ajax({
        context: this,
        type: "POST",
        url: "/messages/contact.php",
        data: form,
      })
        .done(function (result) {
          if (result.data !== "OK") {
            this.resetForm();
          } else {
            this.setState({
              mailSent: false,
              error: "Message failed to send.",
            });
          }
        })
        .fail(function (result) {
          this.setState({
            msg: 3,
          });
        });
    } else {
      // this.props.verifySent(false, 2);
      this.setState({
        mailSent: false,
        msg: 2,
      });
    }
  }
  resetForm = () => {
    this.myFormRef.reset();
    this.setState({
      name: "",
      email: "",
      title: "",
      message: "",
      mailSent: false,
      error: null,
      nameHelp: false,
      emailHelp: false,
      titleHelp: false,
      messageHelp: false,
    });
    this.setState({
      mailSent: true,
      msg: 1,
    });
    // this.props.verifySent(true, 1);
  };
  validateForm = ({ name, email, title, message }) => {
    let validate = { name, email, title, message },
      isValidate = true,
      regExpName = /^[a-zA-Z].*[\s.]*$/,
      regExpEmail = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState((prevState) => ({
      ...prevState,
      nameHelp: false,
      emailHelp: false,
      titleHelp: false,
      messageHelp: false,
    }));
    for (var key of Object.keys(validate)) {
      if (!validate[key].length > 0) {
        isValidate = false;
        let item = key + "Help";
        this.setState({
          [item]: true,
        });
      }
    }
    if (!regExpName.test(String(name).toLowerCase())) {
      isValidate = false;
      this.setState({
        nameHelp: true,
      });
    }
    if (!regExpEmail.test(String(email).toLowerCase())) {
      isValidate = false;
      this.setState({
        emailHelp: true,
      });
    }
    return isValidate;
  };

  render() {
    return (
      <form
        ref={(el) => (this.myFormRef = el)}
        className="contact-form text-right"
        id="contact-form"
      >
        <div className="form-row">
          <div className="form-group col-md-6 text-left">
            <input
              type="text"
              placeholder="TWOJE IMIĘ"
              className="form-control rounded-0 border-0"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
            {this.state.nameHelp && (
              <small className="form-text">
                Imię musi mieć minimum 1 litere, bez cyfr i znaków specialnych.
              </small>
            )}
          </div>
          <div className="form-group col-md-6 text-left">
            <input
              type="text"
              placeholder="TWÓJ ADRES E-MAIL"
              className="form-control rounded-0 border-0"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            {this.state.emailHelp && (
              <small className="form-text">
                E-mail nie może być pusty i musi być ważny
              </small>
            )}
          </div>
        </div>
        <div className="form-group text-left">
          <input
            type="text"
            placeholder="TYTUŁ WIADOMOŚCI"
            className="form-control rounded-0 border-0"
            value={this.state.title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
          {this.state.titleHelp && (
            <small className="form-text">Uzupełnij tytuł wiadomości</small>
          )}
        </div>
        <div className="form-group text-left">
          <textarea
            placeholder="TREŚĆ WIADOMOŚCI"
            className="form-control rounded-0 border-0"
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
          ></textarea>
          {this.state.messageHelp && (
            <small className="form-text">Uzupełnij treść wiadomości</small>
          )}
        </div>
        {this.state.mailSent && this.state.msg === 1 && (
          <div className="success text-uppercase">
            Dziękujemy za skontaktowanie się z nami.
          </div>
        )}
        {this.state.msg === 2 && (
          <div className="warning text-uppercase">
            Proszę poprawnie uzupełnić pola formularza.
          </div>
        )}
        {this.state.msg === 3 && (
          <div className="warning text-uppercase">
            Błąd serwera, spróbuj pozniej
          </div>
        )}
        {this.state.isLoading && <div className="loader"></div>}
        <button
          type="submit"
          className="btn btn-light rounded-0 border-0 text-uppercase"
          onClick={this.handleFormSubmit.bind(this)}
        >
          Wyślij Wiadomość
        </button>
      </form>
    );
  }
}

export default ContactForm;
