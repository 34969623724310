import React from 'react';

const Footer = () => {
	return ( 
		<footer>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 text-center">
						<p className="footer_text m-0 py-4">Copyright &copy; <a href="#header" className="name">Prime City</a></p>
					</div>
				</div>
			</div>
		</footer>
	 );
}
 
export default Footer;