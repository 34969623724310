import React from "react";
import Newsletter from "../components/Newsletter";
// import logo_2 from "../images/logo_pc.svg";
import logo_2_2 from "../images/logo_pc_2.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  return (
    <header className="header position-relative" id="header">
      <div className="container-fluid nav-container">
        <nav className="navbar navbar-expand-lg px-0 navbar-dark bg-dark">
          <a className="navbar-brand py-0" href="#top">
            Prime City
            {/* <img src={logo_2} alt="Prime City logo" className="banner-logo" /> */}
            <img src={logo_2_2} alt="Prime City logo" className="banner-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#home_about">
                  O nas
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#home_benefits">
                  Korzyści
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#home_collab">
                  Współpraca
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#home_partners">
                  Partnerzy
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#home_contact">
                  Kontakt
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="container-fluid banner pt-5">
        <div className="social_links">
          <a
            href="https://www.facebook.com/RzeszowPrimeCity/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} className="" />
          </a>
        </div>
        {/* <div className="row">
          <div className="col">
            <img src={logo_2} alt="Prime City logo" className="banner-logo" />
          </div>
        </div> */}
        <div className="row position-relative banner__text-container">
          <div className="col-12 banner__text-show--4">
            <h1 className="banner-heading-1">Tworzymy przyszłość</h1>
            <h1 className="banner-heading-2">Dla ciebie</h1>
          </div>
          <div className="col-12 banner__text-show--1">
            <h1 className="banner-heading-3">
              Witaj w miasteczku przyszłości, w którym Twoja firma wykorzysta
              maksimum potencjału dzięki najdogodniejszym warunkom dla jej
              rozwoju.
            </h1>
          </div>
          <div className="col-12 banner__text-show--2">
            <h1 className="banner-heading-3">
              Witaj w świecie wizjonerów, dla&nbsp;których wizja budowania
              przyszłości jest na wyciągnięcie ręki.
            </h1>
          </div>
          <div className="col-12 banner__text-show--3">
            <h1 className="banner-heading-3">
              Witaj w&nbsp;Prime City, miasteczku technologii i&nbsp;zielonej
              energii – najlepszym miejscu dla&nbsp;Twojego&nbsp;biznesu!
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col banner-newsletter">
            <Newsletter />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
