import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import "./App.sass";

function App() {
  return (
    <div className="wrapper">
      <Header />
      <main className="main" id="main">
        <Home />
      </main>
      <Footer/>
    </div>
  );
}

export default App;
