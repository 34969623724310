import React from "react";

import about_img from "../images/home_about_1.jpg";
import logo_pbg from "../images/pbg_logo.png";
import logo_pbi from "../images/logo_pbi.png";
import logo_as24 from "../images/logo_agro-serwis-24.png";
import logo_fintechsa from "../images/logo_fintechsa.png";
import home_icon from "../images/home_icon.svg";
import call_icon from "../images/call_icon.svg";
import envelope_icon from "../images/envelope_icon.svg";

import Map from "../components/Map";
import ContactForm from "../components/ContactForm";

const Home = () => {
  return (
    <div className="home" id="home">
      <section className="home_about position-relative mt-5" id="home_about">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 text-center">
              <h1 className="section_heading">O nas</h1>
              <div className="heading-border mb-3 mt-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="section_text mb-4 text-justify">
                Prime City to pomysł trzech wizjonerów, którzy chcą wykorzystać
                potencjał innowacyjnego miasta i zbudować przestrzeń dla firm,
                które patrzą w daleką przyszłość i dają sobie możliwość
                optymalnego rozwoju i funkcjonowania.
              </p>
              <p className="section_text mb-4 text-justify">
                Miasteczko połączy ze sobą branżę IT i gier, biotechnologiczną,
                kosmiczną, żywieniową, sportową, rekreacyjną, co pozwoli na
                podejmowanie wspólnych działań czy wzajemne wsparcie na każdym
                podłożu. Ale to nie wszystko – Prime City będzie
                samowystarczalnym miejscem, w którym znajdą się również
                nowoczesne mieszkania dla pracowników i ich rodzin, strefy
                relaksu i rozrywki, sklepy i lokale gastronomiczne.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                src={about_img}
                alt="about us foto"
                className="img-shadow img-about_us"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home_benefits mb-5" id="home_benefits">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section_heading mb-5 mt-5">Prime City to...</h1>
            </div>
          </div>
          <div className="row">
            <div className="col benefits_box-container">
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--1 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">Firmy</p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--2 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">
                    Mieszkania dla pracowników
                  </p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--3 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">Strefa Relaksu</p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--4 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">
                    Restauracje i&nbsp;kawiarnie
                  </p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg  bg--5 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">Sklepy</p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--6 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">Prime Coin</p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--7 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">Zielona energia</p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
              <div className="benefits_box mb-4 position-relative">
                <div className="bg bg--8 position-relative">
                  <div className="cover position-absolute"></div>
                  <p className="text-2 position-absolute">
                    Przestrzeń dla rozwoju
                  </p>
                </div>
                <div className="shape position-absolute"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_quote mb-5" id="home_qoute">
        <div className="container-fluid">
          <div className="row text-center py-lg-5 py-3">
            <div className="col-12 quote_sign">"</div>
            <div className="col-12 quote_text">
              Naszym celem jest wspieranie firm w&nbsp;każdym aspekcie
              ich&nbsp;życia. Tworzymy przyjemną infrastrukturę dla&nbsp;biznesu
              i&nbsp;dajemy możliwość ścisłej kooperacji firm z&nbsp;wielu branż
            </div>
            <div className="col-12 quote_author position-relative my-lg-5 my-4">
              Prime City
            </div>
          </div>
        </div>
      </section>
      <section className="home_collab position-relative" id="home_collab">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="section_text mb-4 text-justify">
                Prime City to eksperyment społeczny, który ukaże w jaki sposób
                dobrze zagospodarowana przestrzeń wpłynie na rozwój firm,
                zadowolenie pracowników i zwiększenie zysków. Jednym z
                najbardziej eksperymentalnych elementów będzie wprowadzenie
                kryptowaluty: PrimeCoin, która w legalny sposób, wspólnie z
                polskimi złotówkami, będzie obowiązywać na terenie miasteczka.
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 text-center">
              <h1 className="section_heading">Współpraca</h1>
              <div className="heading-border mb-3 mt-4"></div>
            </div>
          </div>
          <div className="row home_collab-img-container flex-column flex-md-row mb-4">
            <div className="col">
              <div className="home_collab-img home_collab-img--1 img-shadow"></div>
            </div>
            <div className="col">
              <div className="home_collab-img home_collab-img--2 img-shadow d-none d-md-block"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="section_text mb-5 text-justify">
                Poszukujemy ludzi dla których najbardziej abstrakcyjne pomysły
                mają szansę zaistnieć w rzeczywistości. Jeśli – tak jak my –
                jesteście wizjonerami o nieograniczonym myśleniu – dołączcie do
                nas. Potrzebujemy wsparcia nie tylko finansowego, ale również
                merytorycznego. Wszystkie firmy, które dołączą już na etapie
                tworzenia PrimeCity, znajdą również swoje miejsce na jego
                terenie.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home_quote mb-5" id="home_qoute">
        <div className="container-fluid">
          <div className="row text-center py-lg-5 py-3">
            <div className="col-12 quote_sign">"</div>
            <div className="col-12 quote_text">
              Wizję, którą chcemy wprowadzić w&nbsp;życie do jej realizacji
              wcale nie musi oddzielać długa droga – połączmy nasze siły
              i&nbsp;zacznijmy budować przyszłość już&nbsp;dziś!
            </div>
            <div className="col-12 quote_author position-relative my-lg-5 my-4">
              Prime City
            </div>
          </div>
        </div>
      </section>
      <section className="home_creators my-lg-3" id="home_creators">
        <div className="container">
          <div className="row">
            <div className="col-12 postition-relative">
              <h1 className="section_heading text-center pb-4">
                Współtworzą program
              </h1>
              <span className="section_small_line section_small_line_center position-absolute"></span>
            </div>
            {/* <div className="col-12 text-center">
              <p className="section_text my-4 py-3">
                We create unique design objects made of rare wood, specially
                under the order. Look at the short video about our work.
              </p>
            </div> */}
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-6 col-lg-3 my-3 d-flex justify-content-center align-items-center">
              <a
                href="https://primebitgames.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_pbg} alt="PrimeBit Games Logo" />
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 my-3 d-flex justify-content-center align-items-center">
              <a
                href="http://primebitinvestments.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_pbi} alt="PrimeBit Investments Logo" />
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 my-3 d-flex justify-content-center align-items-center">
              <a
                href="http://agro-serwis24.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_as24} alt="Agro-Serwis-24 Logo" />
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-3 my-3 d-flex justify-content-center align-items-center">
              <a
                href="http://www.fintechsa.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_fintechsa} alt="Fintech SA Logo" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="home_contact pb-5" id="home_contact">
        <div className="container">
          <div className="row">
            <div className="col-12 postition-relative my-md-5 my-4">
              <h1 className="section_heading text-center pb-md-4 pb-3 text-white">
                Kontakt
              </h1>
              <span className="section_small_line section_small_line_center position-absolute"></span>
            </div>
          </div>
          <div className="row map_and_address">
            <div className="col-12 col-md-5 p-0 map_container order-2 order-md-1">
              <Map />
            </div>
            <div className="col-12 col-md-7 pl-md-4 order-1 order-md-2">
              <h3 className="contact_title text-uppercase my-4">Prime City</h3>
              <div className="contact_line mb-3"></div>
              <p className="contact_text mb-0">
                Masz pytanie? Potrzebujesz pomocy?
              </p>
              <p className="contact_text">Chętnie Ci pomożemy.</p>
              <div className="contact_address">
                <p className="contact_text contact address position-relative ml-4 pl-2 mb-2">
                  <img
                    src={home_icon}
                    alt=""
                    className="position-absolute contact_icon"
                  />
                  Szlachecka 1, os. Przybyszówka (Dworzysko)
                </p>
                <p className="contact_text contact address position-relative ml-4 pl-2 mb-3">
                  35-213 Rzeszów
                </p>
                <p className="contact_text contact address position-relative ml-4 pl-2 mb-2">
                  <img
                    src={envelope_icon}
                    alt=""
                    className="position-absolute contact_icon"
                  />
                  <a href="mailto:office@primebitgames.com">
                    office@primebitgames.com
                  </a>
                </p>
                <p className="contact_text contact address position-relative ml-4 pl-2">
                  <img
                    src={call_icon}
                    alt=""
                    className="position-absolute contact_icon"
                  />
                  <a href="tel:+48 17 28 31 870">+48 17 28 31 870</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 px-sm-0 px-3">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
