import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import marker_icon from "../images/marker.svg";

const Marker = ({ text }) => (
  <div
    style={{
      position: "absolute",
      transform: "translate(-15%, -110%)",
      display: "flex",
    }}
  >
    <img src={marker_icon} alt="" />
    <p
      className="m-0"
      style={{ fontSize: "14px", color: "#ea4335", fontWeight: "500" }}
    >
      {text}
    </p>
  </div>
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 50.064265,
      lng: 21.942448,
    },
    zoom: 17,
  };

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC_O6g5GRsD2NFAMw542tSjNIA1p0VgkUg" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={50.064265} lng={21.942448} text="Szlachecka&nbsp;1" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
